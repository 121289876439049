@import 'styles/rem.scss';

:root {
    --white: #ffffff;
    --red: #f55a5a;
    --black: #1c1b1f;
    --light-black: #1d1d1b;
    --gray: #a29fa8;
    --gray-border: #d1d0d2;
    --light-gray: #f4f4f5;
    --dark-gray: #dddfe3;
    --primary-purple: #6d62ef;
    --primary-purple-dark: #5348cf;
    --purple-less: #6d62ef;
    --purple-light: #e2dffc;
    --primary-mute: #a29fa8;
    --gray-silver: #e8e8ed;

    --transparent-white: rgba(255, 255, 255, 0.5);

    --light-red: rgba(245, 90, 90, 0.1);

    --purple-005: rgba(109, 98, 239, 0.05);
    --purple-02: rgba(109, 98, 239, 0.2);
    --purple-01: rgba(109, 98, 239, 0.1);

    --gray-005: rgba(23, 20, 30, 0.05);
    --gray-01: rgba(23, 20, 30, 0.1);
    --dark-003: rgba(16, 16, 22, 0.03);

    --card-shadow: 0px 6px 32px rgba(110, 128, 153, 0.1);
    --card-shadow-hover: 0px 6px 32px rgba(110, 128, 153, 0.3);
    --card-shadow-white-box: 0px 5px 44px rgba(110, 128, 153, 0.1);

    --main-background-color: var(--light-gray);

    --text-size-s1: #{rem(30px)};
    --text-size-s2: #{rem(26px)};
    --text-size-s3: #{rem(20px)};
    --text-size-s4: #{rem(18px)};
    --text-size-s5: #{rem(16px)};
    --text-size-s6: #{rem(14px)};
    --text-size-s7: #{rem(12px)};

    --rounding-xs: #{rem(8px)};

    --text-color-default: var(--black);
    --text-color-secondary: var(--gray);

    --font-family-default: 'sans-serif';
}

//colors
$mainGreen: #6d62ef;
$mainPurple: #9853f6;
$newMainPurple: #893aee;

$main-font-color: rgba(28, 27, 31, 1);
$mute-font-color: rgba(162, 159, 168, 1);

//shadows
$shadow-default: 0px rem(6px) rem(32px) rgba(110, 128, 153, 0.1);
$shadow-thick: 0 rem(5px) rem(24px) rgba(110, 128, 153, 0.3);
$shadow-login: 0 rem(10px) rem(30px) rem(-10px) rgba(29, 29, 27, 0.12);
$shadow-attachments: 0px 5px 44px rgba(110, 128, 153, 0.1);
$default-border: 1px solid rgba(181, 181, 182, 0.2);

$shadow-new: 0px rem(6px) rem(32px) rgba(110, 128, 153, 0.1);
$shadow-new-hover: 0px rem(10px) rem(32px) rgba(110, 128, 153, 0.2);

@mixin mainGradient {
    background-image: linear-gradient(97deg, $mainGreen, $mainPurple 100%);
}

$maxPhoneWidth: 568px;

@mixin mobilePortrait {
    @media (max-width: $maxPhoneWidth) and (orientation: portrait), (max-width: 500px) and (orientation: landscape) {
        @content;
    }
}

@mixin mobileLandscape {
    @media (max-height: $maxPhoneWidth) and (orientation: landscape) {
        @content;
    }
}

@mixin mobileBothOrientations {
    @media (max-width: $maxPhoneWidth) and (orientation: portrait),
        (max-height: $maxPhoneWidth) and (orientation: landscape) {
        @content;
    }
}
